import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import CustomSelect from "../Custom Components/CustomSelect";
import React from "react";
import NavigationBar from "../NavigationBar";
import RoundTextField from "../Custom Components/RoundTextField";
import GrayCheckBox from "../Custom Components/GrayCheckBox";
import { CountryRegionData } from "react-country-region-selector";
import config, { titleOptions } from "../../config";
import { CustomRadio } from "../Custom Components/StandardRadio";
import StandardButton from "../Custom Components/StandardButton";
import { post, postLog, sendEmail } from "../../js/httpRequests";
import HelpIcon from "@material-ui/icons/Help";
import {
  nameTester,
  nameTesterHelperText,
  passwordTester,
  passwordTesterHelperText,
} from "../../js/auxFunctions";
import axios from "axios";
var crypto = require('crypto');


const CustomTooltip = (props) => {
  return (
    <Tooltip
      title={
        !props.customTitle ? (
          <p className="pt-3">
            Must respect the following:
            <ul className="pl-3">
              {props.isRequired ? <li>Not empty</li> : null}
              <li>No special characters</li>
              <li>Maximum 25 characters</li>
            </ul>
          </p>
        ) : (
          props.customTitle
        )
      }
      className="mb-3"
    >
      <HelpIcon style={{ fontSize: 15 }} />
    </Tooltip>
  );
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      user: {
        title: "",
        gender: "",
        readTerms: false,
        organization: "",
        middleName: "",
        country: "",
      },
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  onEventChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  onSelectChange = (select, event) => {
    const { value, label } = select;
    const { name } = event;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: { value: value, label: label },
      },
    }));
  };

  onCountryChange = (value) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        country: value,
      },
    }));
  };

  onCheckboxChange = (event) => {
    let { checked } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        readTerms: checked,
      },
    }));
  };

  validate = () => {
    const { user } = this.state;
    let temp = {};
    temp.firstName = !nameTester(user.firstName);
    temp.middleName =
      !config.specialRegex.test(user.middleName) &&
      user.middleName.length <= 25;
    temp.lastName = !nameTester(user.lastName);
    temp.email = config.emailRegex.test(user.email);
    temp.password = !passwordTester(user.password);
    temp.confirmPassword = user.password === user.confirmPassword;
    temp.organization = user.organization.length >= 3;
    temp.country = user.country !== "";
    temp.gender = user.gender !== "";
    temp.readTerms = user.readTerms;
    temp.title = user.title !== "";
    return Object.values(temp).every((x) => x === true);
  };

  onSubmit = async (event) => {
    event.preventDefault();
    if (this.validate()) {
      let user = Object.assign({}, this.state.user);
      user.country = user.country[0];
      user.idTitle = user.title.value;
      user.title = user.title.label;

      var alg = 'des-ede-cbc';


      var key = new Buffer('primorisportalcp', 'utf-8');
      var iv = new Buffer('EjRWeJCrze8=', 'base64');

      var source = user.password;

      var cipher = crypto.createCipheriv(alg, key, iv);
      var encoded = cipher.update(source, 'ascii', 'base64');
      encoded += cipher.final('base64');

      user.password = encoded;
      // await axios.post(
      //   "https://www.insticc.org/PrimorisWebServices/api/MyAccount/UpdateInfo",
      //   user,
      //   { withCredentials: false }
      // );

      post(
        user,
        "users",
        async () => {
          postLog(
            "Register",
            "User registered",
            user.firstName + " " + user.lastName,
            user.email
          );
          await sendEmail("Confirm Account", this.state.user);

          window.location.href = "/";
        },
        "Account successfully created.\n Check your email for further instructions on how to activate it.",
        true
      );

    }
  };

  render() {
    const { user, width } = this.state;
    return (
      <div>
        <NavigationBar />
        <div className="content">
          <img src="../Default.png" alt="Banner" style={{ width: "100%" }} />
          <Box className="main-box">
            <h3 className="yellow-text">
              <b>Sign Up - Create Account</b>
            </h3>
            <br></br>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>Title:</b>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      {/* <CustomSelect
                        options={titleOptions}
                        backgroundColor="#ece9d6"
                        onChange={this.onSelectChange}
                        defaultValue={titleOptions[0]}
                        height="30px"
                        paddingBottom="33px"
                        name="title"
                        helperText={!user.title ? "Required" : ""}
                      /> */}


                      <RoundTextField
                        name="title"
                        backgroundColor="#ece9d6"
                        select
                        onChange={this.onEventChange}
                        error={!user.title}
                        helperText={!user.title ? "Required" : ""}
                      >
                        {titleOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </RoundTextField>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>
                        First Name:
                        <CustomTooltip isRequired />
                      </b>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                      <RoundTextField
                        name="firstName"
                        backgroundColor="#ece9d6"
                        error={nameTester(user.firstName)}
                        helperText={nameTesterHelperText(user.firstName)}
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>
                        Middle Name:
                        <CustomTooltip />
                      </b>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                      <RoundTextField
                        name="middleName"
                        backgroundColor="#ece9d6"
                        error={
                          config.specialRegex.test(user.middleName) ||
                          user.middleName.length > 25
                        }
                        helperText={
                          config.specialRegex.test(user.middleName)
                            ? "Invalid Characters"
                            : user.middleName.length > 25
                              ? "Maximum characters exceeded"
                              : ""
                        }
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>
                        Last Name:
                        <CustomTooltip isRequired />
                      </b>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                      <RoundTextField
                        name="lastName"
                        backgroundColor="#ece9d6"
                        error={nameTester(user.lastName)}
                        helperText={nameTesterHelperText(user.lastName)}
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>Email:</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <RoundTextField
                        name="email"
                        backgroundColor="#ece9d6"
                        error={!config.emailRegex.test(user.email)}
                        helperText={
                          !config.emailRegex.test(user.email)
                            ? "Invalid email format"
                            : ""
                        }
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>
                        Password:
                        <CustomTooltip
                          customTitle={
                            <p className="pt-3">
                              Must respect the following:
                              <ul className="pl-3">
                                <li>Not empty</li>
                                <li>At least 1 uppercase</li>
                                <li>At least 1 lowercase</li>
                                <li>At least 1 special character</li>
                                <li>At least 1 digit</li>
                                <li>Between 8 and 16 characters</li>
                              </ul>
                            </p>
                          }
                        />
                      </b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <RoundTextField
                        type="password"
                        name="password"
                        backgroundColor="#ece9d6"
                        error={passwordTester(user.password)}
                        helperText={passwordTesterHelperText(user.password)}
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>Confirm Password:</b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <RoundTextField
                        type="password"
                        name="confirmPassword"
                        backgroundColor="#ece9d6"
                        error={
                          user.password !== user.confirmPassword &&
                          user.password
                        }
                        helperText={
                          user.password !== user.confirmPassword &&
                            user.password
                            ? "Passwords dont match"
                            : ""
                        }
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>
                        Organization:
                        <CustomTooltip
                          customTitle={
                            <p className="pt-3">
                              Organization needs to have at least 3 characters.
                              {"\n"}
                              If you do not have an Organization, please add
                              'Independent Researcher'.
                            </p>
                          }
                        />
                      </b>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <RoundTextField
                        name="organization"
                        backgroundColor="#ece9d6"
                        error={user.organization.length < 3}
                        helperText={
                          user.organization.length < 3 ? "Invalid format" : ""
                        }
                        onChange={this.onEventChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={width >= 600 ? "text-align-right" : ""}
                    >
                      <b>Country:</b>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                      <RoundTextField
                        name="country"
                        backgroundColor="#ece9d6"
                        select
                        onChange={this.onEventChange}
                        error={!user.country}
                        helperText={!user.country ? "Required" : ""}
                      >
                        {CountryRegionData.map((option) => (
                          <MenuItem key={option[0]} value={option}>
                            {option[0]}
                          </MenuItem>
                        ))}
                      </RoundTextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={3}
                      className={width >= 600 ? "text-align-right" : ""}
                      style={{ marginBottom: !user.gender ? "25px" : "" }}
                    >
                      <b>Gender:</b>
                    </Grid>
                    <Grid item xs>
                      <CustomRadio
                        checked={this.state.user.gender === "Male"}
                        onChange={this.onEventChange}
                        name="gender"
                        value="Male"
                      />
                      <b>Male</b>
                      <CustomRadio
                        checked={this.state.user.gender === "Female"}
                        onChange={this.onEventChange}
                        name="gender"
                        value="Female"
                      />
                      <b>Female</b>
                      <br></br>
                      {!user.gender ? (
                        <b
                          className="red-text pl-2"
                          style={{ fontSize: "13px" }}
                        >
                          Required
                        </b>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormControlLabel
                control={
                  <GrayCheckBox
                    checked={user.readTerms}
                    onChange={this.onCheckboxChange}
                    name={"readTerms"}
                  />
                }
                labelStyle={{ zIndex: 3 }}
                label={
                  <label className="pt-2">
                    I have read and agree with the above{" "}
                    <a
                      href="/PrivacyPolicy"
                      className="yellow-text"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    , and I authorize to be contacted by email regarding INSTICC
                    events only.
                  </label>
                }
                className="mt-5"
              />
              <Grid container justify="center" className="pt-2 pb-5">
                <StandardButton
                  type="submit"
                  variant="outlined"
                  disabled={!this.validate()}
                  onClick={this.onSubmit}
                >
                  Create an Account
                </StandardButton>
              </Grid>
            </form>
          </Box>
        </div>
      </div>
    );
  }
}

export default SignUp;
